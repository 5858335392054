/* InputNumberUI   ----------------------------------------------------------------------------- */
.input-number-ui input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   width: 0;
   height: 0;
   margin: 0;
}

.input-number-ui:hover .box {
   opacity: 100;
}

/* .input-number-ui:hover .inui-foreground {
    filter: brightness(1.20)
} */

.input-number-ui .input[type='text'] {
   min-width: none;
   max-width: 100%;
   height: 200px !important;
}

.input-number-roundness {
   border-radius: var(--roundness);
}
