/** debug focus stuff
*:active {
    outline: dashed 2px blue !important;
}
*:focus {
    outline: dashed 2px red !important;
}
*:focus-visible {
    outline: dashed 2px orange !important;
}
/**/

/* global vars ------------------------------------------------------------------------------------ */
:root {
    /* --widget-height: 3rem; */
    /* --widget-height: 1.6rem; */
    --widget-height: 2rem;
    --input-height: 1.6rem;
    --input-icon-height: 1.4rem;
}

.UI-WidgetHeaderContainer,
.UI-WidgetLabelContainer,
.UI-WidgetHeaderControlsContainer {
    min-height: var(--widget-height);
}

/* .ABDDE { */
/*     border: 1px solid red; */
/* } */

/* Box Utils ------------------------------------------------------------------------------------- */
/* box sizes */
.box.box-square {
    display: flex;
    justify-content: center;
    align-items: center;
}
.box.box-square.box-input {
    width: var(--input-height);
    height: var(--input-height);
    min-width: var(--input-height);
    min-height: var(--input-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.box.box-square.box-widget {
    width: var(--widget-height);
    height: var(--widget-height);
    min-width: var(--widget-height);
    min-height: var(--widget-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.box.box-square.box-inside {
    width: var(--inside-height);
    height: var(--inside-height);
    min-width: var(--inside-height);
    min-height: var(--inside-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}


.box.box-square.box-xs {
    height: 1.5rem;
    width: 1.5rem;
}
.box.box-square.box-sm {
    height: 2rem;
    width: 2rem;
}
.box.box-square.box-md {
    height: 2.5rem;
    width: 2.5rem;
}
.box.box-square.box-lg {
    height: 3rem;
    width: 3rem;
}
.box.box-square.box-xl {
    height: 3.5rem;
    width: 3.5rem;
}

.box.box-input {
    height: var(--input-height);
    min-height: var(--input-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.box.box-widget {
    height: var(--widget-height);
    min-height: var(--widget-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}


.box.box-inside {
    height: var(--inside-height);
    min-height: var(--inside-height);
    font-size: 0.875rem;
    line-height: 1.25rem;
}


.box.box-xs {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
}
.box.box-sm {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.box.box-md {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.box.box-lg {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.box.box-xl {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
}

/* ------------------------------------------------------------------------------------------------------- */
/* .WIDGET-BLOCK { */
/* margin-left: .6rem; */
/* margin-top: .1rem; */
/* margin-right: .4rem; */
/* padding: .4rem; */
/* padding-top: .8rem;  */
/* padding-bottom: .8rem; */
/* } */

.animated {
    transition: height 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* ------------------------------------------------------------------------------------------------------- */

/* CELL SIZE */
.w-widget {
    width: var(--widget-height);
    min-width: var(--widget-height);
}
.h-widget {
    height: var(--widget-height);
    min-height: var(--widget-height);
}
.minh-widget {
    min-height: var(--widget-height);
}
.minw-widget {
    min-width: var(--widget-height);
}
.lh-widget {
    line-height: var(--widget-height);
}

.h-widget-2 {
    height: calc(var(--widget-height) - 2px);
}
.w-widget-2 {
    width: calc(var(--widget-height) - 2px);
}
.minh-widget-2 {
    min-height: calc(var(--widget-height) - 2px);
}
.minw-widget-2 {
    min-width: calc(var(--widget-height) - 2px);
}
.lh-widget-2 {
    line-height: calc(var(--widget-height) - 2px);
}

/* INPUT SIZE */
.w-input {
    width: var(--input-height);
    min-width: var(--input-height);
}
.h-input {
    height: var(--input-height);
    min-height: var(--input-height);
}
.minh-input {
    min-height: var(--input-height);
}
.minw-input {
    min-width: var(--input-height);
}
.lh-input {
    line-height: var(--input-height);
}

.h-input-2 {
    height: calc(var(--input-height) - 2px);
}
.w-input-2 {
    width: calc(var(--input-height) - 2px);
}
.minh-input-2 {
    min-height: calc(var(--input-height) - 2px);
}
.minw-input-2 {
    min-width: calc(var(--input-height) - 2px);
}
.lh-input-2 {
    line-height: calc(var(--input-height) - 2px);
}

/* INSIDE */
.w-inside {
    width: var(--inside-height);
    min-width: var(--inside-height);
}
.h-inside {
    height: var(--inside-height);
    min-height: var(--inside-height);
}
.minh-inside {
    min-height: var(--inside-height);
}
.minw-inside {
    min-width: var(--inside-height);
}
.lh-inside {
    line-height: var(--inside-height);
}

.h-inside-2 {
    height: calc(var(--inside-height) - 2px);
}
.w-inside-2 {
    width: calc(var(--inside-height) - 2px);
}
.minh-inside-2 {
    min-height: calc(var(--inside-height) - 2px);
}
.minw-inside-2 {
    min-width: calc(var(--inside-height) - 2px);
}
.lh-inside-2 {
    line-height: calc(var(--inside-height) - 2px);
}

/**
 * padding to go from input size to inside size
 */
.p-input {
    padding: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
/* minus 1px to account for border */
.p-input-2 {
    padding-top: calc((var(--input-height) - var(--inside-height)) / 2 - 1px);
    padding-bottom: calc((var(--input-height) - var(--inside-height)) / 2 - 1px);
    padding-left: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
    padding-right: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.py-input {
    padding-top: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
    padding-bottom: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.px-input {
    padding-left: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
    padding-right: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.pt-input {
    padding-top: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.pb-input {
    padding-bottom: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.pl-input {
    padding-left: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}
.pr-input {
    padding-right: calc((var(--input-height) - var(--inside-height)) / 2 - 0px);
}

/**
* padding to go from cell/widget size to input size
*/
.p-widget {
    padding: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.py-widget {
    padding-top: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
    padding-bottom: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.px-widget {
    padding-left: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
    padding-right: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.pt-widget {
    padding-top: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.pb-widget {
    padding-bottom: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.pl-widget {
    padding-left: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}
.pr-widget {
    padding-right: calc((var(--widget-height) - var(--input-height)) / 2 - 0px);
}

/**
* padding to go from cell/widget size to inside size
*/
.p-widget-inside {
    padding: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.py-widget-inside {
    padding-top: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
    padding-bottom: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.px-widget-inside {
    padding-left: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
    padding-right: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.pt-widget-inside {
    padding-top: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.pb-widget-inside {
    padding-bottom: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.pl-widget-inside {
    padding-left: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}
.pr-widget-inside {
    padding-right: calc((var(--widget-height) - var(--inside-height)) / 2 - 0px);
}

.COLLAPSE-PASSTHROUGH:hover .WIDGET-COLLAPSE-BTN {
    opacity: 100% !important;
}

.UI-Color,
.UI-Select,
.UI-InputNumber,
.UI-InputString,
.UI-InputDate {
    border-radius: 0.375rem;
}

/* ------------------------------------------------------------------------------------------- */
/* make sure cushy default inputs look like custom inputs */
.csuite-input:focus,
.csuite-basic-input:focus {
    outline: none;
    border: 1px solid oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h);
}
.csuite-input:hover,
.csuite-basic-input:hover {
    background: oklch(from var(--KLR) calc(l + 0.06 * var(--DIR)) c h);
}
.csuite-input,
.csuite-basic-input {
    /* 🔴 FINISH ME */
    min-height: var(--input-height);
    background: oklch(from var(--KLR) calc(l + 0.03 * var(--DIR)) c h);
    border: 1px solid oklch(from var(--KLR) calc(l + 0.1 * var(--DIR)) c h);
    border-radius: 0.2rem;
    padding: 0 0.3rem;
}
.csuite-basic-input {
    height: var(--input-height);
}

/* Form Utils ------------------------------------------------------------------------------------- */
/* Text Shading - These are defined in theme.css*/
.text-shadow {
    text-shadow: 0px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        1px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        -1px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h);
}

.text-shadow-inv {
    text-shadow: 0px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        1px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        -1px 1px 1px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h);
}

/* Select Utils ------------------------------------------------------------------------------------- */
._SelectPopupUI,
._RevealUI {
    box-shadow: 0 15px 10px -10px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        15px 15px 10px -10px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h),
        -15px 15px 10px -10px oklch(from var(--KLR) calc(l + 0.2 * var(--DIR)) c h);
    /* 0 0 10px 1px oklch(var(--b3)); */
}

/* 🔶 deprecated; will be removed soon */
.rsx-field-error {
    border: 1px solid red !important;
}
