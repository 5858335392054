.BOX {
   background: var(--KLR);
}

html,
body,
#root {
   height: 100%;
   --line-height: 1.5em;
   line-height: 1.5;

   /* 🔴 fix this */
   --rs-cyan-500: #626262 !important;
   /* 🔶 loco blue on button */
   /* --rs-btn-primary-bg: #4168b5 !important;
   --rs-iconbtn-primary-addon: #385eaa !important; */
   /* Moved from LAYOUT_CONSTS */
   --x-menuColor: #313131;
   --dynamicPadding1: 0.5rem;
}

.bordered {
   border: 1px solid #444;
}

body {
   margin: 0;
   font-family:
      'Inter',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #4a4a4a;
   font-size: 14px;
   background-color: #fafafa;
   /* safe-area properties are needed to adapt the app to special mobile screens like iphone 14 ...  */
   /* padding-top: env(safe-area-inset-top);  */ /* replaced by a div */
   padding-bottom: env(safe-area-inset-bottom);
   padding-right: env(safe-area-inset-right);
   padding-left: env(safe-area-inset-left);
}

/* _______________Inbox styles_______________ */
.menuUI {
   padding-bottom: env(safe-area-inset-bottom);
   box-shadow: 0 0 10px 1px lightgray;
   background: #242424;
   z-index: 10;
   overflow: auto;
   inset: 0;
   transition: width 0.4s cubic-bezier(0.34, 1.4, 0.64, 1);
}
.customerPanel {
   width: 0;
   overflow: auto;
}

@media screen and (max-width: 768px) {
   .menuUI {
      width: 100vw;
      height: 100vh;
   }
   .convList {
      width: 100vw;
   }

   .customerPanel.open {
      width: 100vw;
   }

   .conv {
      width: 100vw;
   }

   .conv.hide {
      display: none;
   }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
   .menuUI {
      width: 20vw;
      height: 100vh;
   }

   .convList {
      width: 25vw;
      max-width: 300px;
   }

   .conv {
      flex-grow: 1;
      transition: width 0.4s cubic-bezier(0.34, 1.4, 0.64, 1);
      flex-shrink: 0;
   }

   .customerPanel {
      transition: width 0.4s cubic-bezier(0.34, 1.4, 0.64, 1);
   }

   .customerPanel.open {
      width: 20vw;
   }
}

@media screen and (min-width: 1001px) {
   .menuUI {
      width: 20vw;
      height: 100vh;
      max-width: 300px;
   }

   .convList {
      width: 20vw;
      max-width: 300px;
   }

   .conv {
      flex-grow: 1;
      transition: width 0.4s cubic-bezier(0.34, 1.4, 0.64, 1);
   }

   .customerPanel {
      transition: width 0.4s cubic-bezier(0.34, 1.4, 0.64, 1);
   }

   .customerPanel.open {
      width: 20vw;
      max-width: 400px;
   }
}

/* __________________________________ */
code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

code.language-typescript.hljs {
   display: inline-block;
}

textarea {
   resize: none;
}

* {
   /* prevent zoom on double tap on iOS */
   touch-action: manipulation;
}

.scrollable {
   -webkit-overflow-scrolling: touch;
}

/* Fix default placeholders style */
::-moz-placeholder {
   color: #ccc !important;
   opacity: 1 !important;
}
::placeholder {
   color: #ccc !important;
   opacity: 1 !important;
}

.hover-icon {
   cursor: pointer;
}

.hover-clickable:hover {
   background-color: #f1f1f1 !important;
   cursor: pointer;
}

.hover-clickable-highlight:hover {
   background-color: #ffffff50 !important;
   cursor: pointer;
}

/* not enough space between links in navbar by default */
@media (min-width: 992px) {
   .nav-link {
      padding-left: 0.8rem !important;
      padding-right: 0.8rem !important;
   }
}

/* fix blue :active on dropdown-item */
.nav-item .dropdown-item.active,
.dropdown-item:active {
   background-color: #eee;
}

/* improve navbar readability */
.nav-item > .nav-link {
   padding-top: 4px;
   padding-bottom: 4px;
}

.nav-item.active > .nav-link {
   font-weight: 600;
   border-top: 5px solid transparent;
   border-bottom: 5px solid #e55757;
}

/* relayout navbar so links take 100% of navbar height */
.navbar,
.navbar-collapse,
.navbar-nav {
   align-items: stretch;
}
.navbar {
   padding-top: 0;
   padding-bottom: 0;
}

.nav-item {
   display: flex;
}

.nav-item > .nav-link {
   display: flex;
   align-items: center;
}

/* change toastify green and red to locogreen and locored */
.Toastify__toast--success {
   background: #28a745 !important;
}

.Toastify__toast--error {
   background: #e55757 !important;
}

.Toastify__toast-container--bottom-right {
   bottom: 140px !important;
}

/* Customize carousel controls */
.carousel .control-prev.control-arrow:before {
   border-right: 12px solid #054bd6 !important;
}

.carousel .control-next.control-arrow:before {
   border-left: 12px solid #054bd6 !important;
}

.carousel.carousel-slider .control-arrow {
   padding: 15px !important;
}

/* helper class based on bootstrap .btn.disabled */
.disabled-css {
   opacity: 0.65;
}

/* FLEX UTILS */
.fV {
   display: flex;
   flex-direction: column;
   gap: 0.75rem;
}
.fH {
   display: flex;
   flex-direction: row;
   gap: 0.75rem;
}
.fVG {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   gap: 0.75rem;
}
.grow2 {
   flex-basis: 0;
   flex-shrink: 0;
   flex-grow: 1;
}

/* material icons */
.material-symbols-outlined {
   font-family: 'Material Symbols Outlined';
   font-weight: normal;
   font-style: normal;
   font-size: inherit;
   /* Preferred icon size */
   display: inline-block;
   line-height: 1;
   text-transform: none;
   letter-spacing: normal;
   word-wrap: normal;
   white-space: nowrap;
   direction: ltr;
}

/*
2023-01-10 rvion:
we can't inject twin macro GlobalStyles because it either
- conflicts with bootstrap (v2, mandatory preflight)
- need v3 with extra config to disable preflight theme
=> so in the meantime, I'll just inline twin macro keyframes for the 5 base animations
*/

/* prettier-ignore */
@keyframes bounce {
   0%, 100% { transform: translateY(-25%); animation-timing-function: cubic-bezier(0.8, 0, 1, 1); }
   50% { transform: none; animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
}

/* prettier-ignore */
@keyframes pulse {
   50% { opacity: .5; }
}

/* prettier-ignore */
@keyframes ping {
   100% { transform: scale(1.2); opacity: 0.3; }
}

/* prettier-ignore */
@keyframes spin {
   to { transform: rotate(360deg); }
}

a:hover {
   color: inherit;
   text-decoration: none;
}
a {
   /* color: inherit; */
   color: unset;
   text-decoration: none;
}

fieldset {
   border: none;
}

/* BAD CODE */
.ROW_PAUSED {
   background-color: #fbf8e1 !important;
}

.rs-panel-title {
   font-weight: bold;
}

/** 2023-09-27 changed for many reaons
  * we're B2B,
  * we need space estate in lots of pages
  * we're mobile too
  * pages can have BG, etc.
  * please let's have a discussion first if we want to change this
  */
.rs-panel .rs-panel-body,
.rs-panel .rs-panel-header {
   padding: 10px;
   /* background: red; */
}
.rs-panel.p-0 .rs-panel-body,
.rs-panel.p-0 .rs-panel-header {
   padding: 0;
}

/* add contrast to the rs-panel */
.rs-panel {
   background: white;
}

/* make checkbox group denser */
.rs-checkbox-group.superdense .rs-checkbox-wrapper {
   top: 0;
}
.rs-checkbox-group.superdense .rs-checkbox-checker {
   min-height: 1rem;
   padding-top: 0;
   padding-bottom: 0;
}

.rs-rate-character {
   margin-right: 3px !important;
}

/* makes tabs growable and dark and stuff... whatever it's still kinda ugly */
/* --rs-navs-selected: rgb(36, 36, 36);
--rs-navs-text: rgb(118, 118, 118); */
/* body {
} */

.rs-nav.nav-grow {
   display: flex;
}

.rs-nav.nav-grow .rs-nav-item {
   flex-grow: 1;
   text-align: center;
   /* background-color: #f3f3f3; */
}

.rs-nav.nav-grow .rs-nav-item-active {
   font-weight: 600;
   /* background-color: #fafafa; */
}
.rs-message-icon-wrapper .rs-icon {
   font-size: 1.3rem;
}
.rs-navbar-default {
   background: linear-gradient(90deg, #12699d, #4d5b8f) !important;
}

/* improves chances of rough-notation being visible */
.rough-annotation {
   z-index: 100;
}

.ellipsis {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}

/* more link-like button when appearance='link' and as={Link}/as='a' */
a.rs-btn-link {
   padding: unset;
   vertical-align: unset;
}
.rs-message.rs-message-success {
   border: 1px solid #379d25;
}
.rs-message.rs-message-info {
   border: 1px solid #91d5ff;
}
.rs-message.rs-message-warning {
   border: 1px solid #ffbb00;
}
.rs-message.rs-message-error {
   border: 1px solid #cf5c36;
}
.rs-message-container {
   padding: 0.3rem !important;
   /* 20px 28px 20px 20px */
}

/* softer color for the selected item in a picker */
.rs-picker.picker-value-soft-text .rs-stack-item .rs-picker-toggle-value {
   color: var(--rs-text-primary);
}

/* dark picker */
.rs-picker.dark .rs-picker-toggle {
   background-color: #272727 !important;
   border-color: #3c3f43 !important;
}
.rs-picker.dark .rs-picker-toggle-value {
   color: #fff !important;
}

/* custom cleanable picker for search */
.rs-custom-select .rs-picker-toggle-clean.rs-btn-close {
   color: #fff;
   padding-top: 0.5rem;
   padding-right: 0.5rem;
}

.badge-over-select .rs-badge-content {
   /* when we put a badge on a SelectPicker, by default it appears below it (select has z-index: 5) */
   z-index: 6 !important;
}

.badge-hidden .rs-badge-content {
   opacity: 0;
}

blockquote {
   border-left: 3px solid gray;
   padding-left: 5px;
   color: gray;
}

.email-preview-container.selected {
   background-color: #f1f1f1;
}

/* ------ Panel RSuite ------ */
/* .rs-panel-bordered {
   border: 1px solid rgb(162, 162, 162) !important;
} */
/* 🦄 Attempt to fix rsuite panel nesting. They  */
.rs-panel-header + .rs-panel-body,
.rs-panel-header + .rs-panel-collapse .rs-panel-body {
   padding-top: 20px !important;
}

.rs-panel-header + .rs-panel-body,
.rs-panel-header + .rs-panel-collapse > .rs-panel-body {
   padding-top: 0 !important;
}

.email-preview-container > .rs-panel-body {
   width: 100%;
}

.rs-panel-collapsible > .rs-panel-header > .rs-icon {
   font-size: 22px;
   top: 10px !important;
}
/* ------ rs-picker ------------- */

/* .rs-picker-toggle.rs-btn.rs-btn-default {
   padding-top: 0.2rem !important;
   padding-bottom: 0.2rem !important;
} */
/* ------ react-split-pane ------ */

.react-split__sash.react-split__sash--vertical:hover {
   background-color: #8f8fc288;
}
.react-split__sash.react-split__sash--vertical {
   background-color: #8f8fc211;
   transform: translateX(-50%);
   width: 10px !important;
}

.react-split__pane {
   background-color: white;
   display: flex;
   flex-direction: column;
   /* border: 1px solid #e5e5e5; */
}

/* AnnounceKit */

.announcekit-frame-wrapper {
   margin-top: env(safe-area-inset-top);
}

/* CushyStudio scrollbar ----------------------- */
*::-webkit-scrollbar {
   width: 15px;
   height: 15px;
}
*::-webkit-scrollbar-corner {
   background: rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-track {
   background-color: rgba(0, 0, 0, 0);
}
*::-webkit-scrollbar-thumb {
   background-color: #a4a4a48b;
   border-radius: 6px;
   border: 4px solid rgba(0, 0, 0, 0);
   background-clip: content-box;
   /* min-width: 24px; */
   /* min-height: 24px; */
}

/* allow removing weird margins from rsuite tags */
.rs-tags-compact > .rs-tag + .rs-tag {
   margin-left: 0;
}

/* pricing */
.pricing-bg::before {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 55%;
   background-color: #cedff1;
   z-index: -1;
}

/* .rdg {
   overflow-y: scroll !important;
} */

.rs-divider-small {
   margin: 0.5rem 0 !important;
}

.invalid-feedback {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 80%;
   color: #dc3545;
}

/* ------ animation ------ */
.animatedpos {
   transition: all 0.2s ease-in-out;
}

.rs-panel {
   overflow: auto !important;
}

.bd1 {
   border: 1px solid gray;
}
.bd2 {
   border: 2px solid blue;
}
.bd,
.bd3 {
   border: 3px solid red;
}

/*
.bg-base-100 {
   background: white;
}
.bg-base-200 {
   background: #eeeeee;
}
.bg-base-300 {
   background: #dddddd;
} */

.rs-drawer-content {
   overflow: auto; /* Really not sure why they use overflow: visible by default... */
   display: flex;
}

.rs-modal-content > div {
   display: flex;
}

.loco-tag-picker > .rs-picker-tag-wrapper {
   display: flex;
}

/* Google Places auto complete suggestions */
.pac-container {
   z-index: 10000;
}

.radio-compact > .rs-radio > .rs-radio-checker > label {
   line-height: unset;
}

/************** daisyUI **************/
.flex-2 {
   flex: 2 1 0%;
}
.btn {
   font-weight: 400;
}
.input {
   background-color: oklch(var(--p) / 0.1);
}
/* .alert {
   @apply border-4 border-solid border-black;
} */

.alert.alert-error {
   border-left-width: 4px;
   --tw-border-opacity: 1;
   border-left-color: rgb(248 113 113 / var(--tw-border-opacity));
   --tw-bg-opacity: 1;
   background-color: rgb(254 242 242 / var(--tw-bg-opacity));
   font-weight: 700;
   --tw-text-opacity: 1;
   color: rgb(153 27 27 / var(--tw-text-opacity));
   /* @apply border-4 border-solid border-black; */
}

.rs-tree.rs-tree-loco {
   height: 100%;
   max-height: unset;
}

.rs-tree-loco .rs-tree-node-label-content {
   line-height: 0.5;
}

.rs-tree-loco .rs-tree-node-expand-icon-wrapper {
   display: flex;
   height: 30px;
   align-items: center;
   line-height: 0.5;
}

.rs-tree-loco .rs-tree-node-label-content {
   font-size: small;
}

::highlight(highlight-native) {
   background-color: rgb(254 240 138); /* bg-yellow-200 */
}
