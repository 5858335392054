.checkmark__circle {
   stroke-dasharray: 166;
   stroke-dashoffset: 166;
   stroke-width: 2;
   stroke-miterlimit: 10;
   stroke: #7ac142;
   fill: none;
   animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
   width: 124px;
   height: 124px;
   border-radius: 50%;
   display: block;
   stroke-width: 3;
   stroke: #7ac142;
   stroke-miterlimit: 10;
   margin: 10% auto;
   box-shadow: inset 0px 0px 0px #7ac142;
   animation: scale 0.2s ease-in-out 0.9s both;
}

.checkmark__check {
   transform-origin: 50% 50%;
   stroke-dasharray: 48;
   stroke-dashoffset: 48;
   animation: stroke 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67) 0.5s forwards;
}

@keyframes stroke {
   100% {
      stroke-dashoffset: 0;
   }
}

@keyframes scale {
   0%,
   100% {
      transform: none;
   }

   50% {
      transform: scale3d(1.2, 1.2, 1);
   }
}

@keyframes fill {
   100% {
      box-shadow: inset 0px 0px 0px 100px #7ac142;
   }
}
