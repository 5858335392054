/*
Any component rendering markdown expect to have a class of `_MD` on the root element.
Known Users:
    - <MarkdownUI /> in `src/rsuite/MarkdownUI.tsx`
    - WidgetMarkdown in `src/controls/fields/markdown/WidgetMarkdown.ts`
*/
/* markdown related stuff */

/* ._MD { padding-left: .5rem } */
._MD h1 {
   font-size: 2rem;
}
._MD h2 {
   font-size: 1.5rem;
}
._MD h3 {
   font-size: 1.17rem;
}
._MD h1,
._MD h2,
._MD h3,
._MD h4 {
   font-weight: bold;
   color: oklch(var(--p));
}

._MD ul,
._MD ol {
   padding-left: 1rem;
   margin-top: 0;
   margin-bottom: 0;
}
._MD ul {
   list-style-type: disc;
}
._MD ol {
   list-style-type: decimal;
}
._MD li {
   margin-left: 1rem;
   /* margin-top: .5rem; */
   /* margin-bottom: .5rem; */
}
._MD table thead th {
   text-align: left;
}
._MD table,
._MD th,
._MD td {
   border-top: 1px solid #333333;
   border-left: 1px solid #333333;
}

._MD code {
   border: 1px solid oklch(var(--p) / 0.2);
   display: block;
   background-color: oklch(var(--p) / 0.1);
   padding: 0.5rem;
   overflow: auto;
   white-space: pre-wrap;
}
._MD code,
._MD pre {
   font-style: italic;
}

._MD table {
   width: 100%;
   border: 2px solid var(--fallback-p, oklch(var(--a) / 0.45));
}
._MD th {
   background: oklch(var(--p) / 0.1);
}
._MD th,
._MD td {
   border-bottom: 1px solid var(--fallback-p, oklch(var(--a) / 0.45));
   border-right: 1px solid var(--fallback-p, oklch(var(--a) / 0.45));
}
